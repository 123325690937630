import { Col, Row } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";

import i18n from "i18next";
import {
  Chat,
  Empty,
  Extra,
  FooterContainer,
  FooterSection,
  Label,
  Language,
  LanguageSwitch,
  LanguageSwitchContainer,
  LogoContainer,
  NavLink,
  Para
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection id="contact">
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              {/* <Large to="/">info@finagree.com.tr</Large> */}
              <Para>
                info@finagree.com.tr
              </Para>
              <br></br>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:info@finagree.com.tr">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
         
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" to="/">
                {t("Support Center")}
              </Large>
              <Large left="true" to="/">
                {t("Customer Support")}
              </Large>
            </Col> */}
          </Row>
          <Row justify="space-between">
          <Col lg={10} md={10} sm={12} xs={12}>
        <Empty />
        <Language>{t("Address")}</Language>
        <Para>
      Finagree Yazılım ve Danışmanlık Ticaret Limited Şirketi<br />
      <address>
        Akfırat Mah. Fatih Sultan Mehmet Bul. No:3<br />
        Kapı No:65<br />
        34959 Tuzla/ İstanbul<br />
        <div>
          <div>
            <span>Tel:</span> <a href="tel:+905062818181">+90 506 281 8181</a>
          </div>
          <div>
            <span>KEP Adresi:</span> <a href="mailto:finagree@hs01.kep.tr">finagree@hs01.kep.tr</a>
          </div>
          <div>
            <span>Mersis No:</span> 0388180207300001
          </div>
          <div>
            <span>Ticaret Sicil No:</span> 475369-5
          </div>
        </div>
      </address>
    </Para>
         </Col>
            {/* <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large left="true" to="/">
                {t("About")}
              </Large>
              <Large left="true" to="/">
                {t("Blog")}
              </Large>
              <Large left="true" to="/">
                {t("Press")}
              </Large>
              <Large left="true" to="/">
                {t("Careers & Culture")}
              </Large>
            </Col> */}
            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("tr")}>
                  <SvgIcon
                    src="turkey.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                 src={isMobile ? "logo-m.svg" : "logo.svg"}
                  aria-label="homepage"
                  width="150px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="https://twitter.com/finagreesoft"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.linkedin.com/company/finagree"
                src="linkedin.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
