import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Routes>
        {routes.flatMap((routeItem) => {
          const LazyComponent = lazy(() => import(`../pages/${routeItem.component}`));
          return routeItem.path.map((path) => (
            <Route
              key={path}
              path={path}
              element={<LazyComponent />}
            />
          ));
        })}
      </Routes>
      <Footer />
    </Suspense>
  );
};

export default Router;
