import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`
        @font-face {
            font-family: "Montserrat Light";
            src: url("/fonts/Montserrat-Light.ttf") format("truetype");
            font-style: normal;
        }
        
        @font-face {
            font-family: "Montserrat Bold";
            src: url("/fonts/Montserrat-Bold.ttf") format("truetype");
            font-style: normal;
        }
        :root {
        --color-text-title-t: #5499d3; //#2e186a
        --color-text-label-t: #091730; //#2e186a
        --color-text-button-t: #5499d3; //#2e186a
        --color-text-button-hover-t:  #71c381; //rfe7624
        }
        
        body,
        html,
        a {
        font-family: 'Montserrat Light', sans-serif;
        }
        
        
        body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
        }
        
        a:hover {
        color: var(--color-text-title-t);
        }
        
        input,
        textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;
        
        :focus-within {
            background: none;
            box-shadow: var(--color-text-button-t) 0px 0px 0px 1px;
        }
        }
        
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
        font-family: 'Montserrat Bold', serif;
        color: var(--color-text-title-t);
        font-size: 54px;
        line-height: 1.16;
        
        @media only screen and (max-width: 890px) {
        font-size: 47px;
        }
        
        @media only screen and (max-width: 414px) {
        font-size: 32px;
        }
        }
        
        p {
        color: var(--color-text-label-t);
        font-size: 21px;        
        line-height: 1.41;
        }
        
        h1 {
        font-weight: 600;
        }
        
        a {
        text-decoration: none;
        outline: none;
        color: var(--color-text-button-t);
        
        :hover {
            color: var(--color-text-button-t);
        }
        }
        
        *:focus {
        outline: none;
        }
        
        .about-block-image svg {
        text-align: center;
        }
        
        .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
        }
        
        .ant-drawer-content-wrapper {
        width: 300px !important;
        }
`;
